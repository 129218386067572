/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answerVisible: false,
    };
    this.toggleAnswer = this.toggleAnswer.bind(this);
  }

  toggleAnswer() {
    this.setState(state => ({
      answerVisible: !state.answerVisible,
    }), this.sendAnalyticsEvent);
  }

  sendAnalyticsEvent() {
    const { answerVisible } = this.state;
    const { question } = this.props;

    if (answerVisible) {
      ReactGA.event({
        category: 'FAQs',
        action: 'Click',
        label: question,
      });
    }
  }

  render() {
    const { question, answer } = this.props;
    const { answerVisible } = this.state;

    return (
      <li className='border-b border-grey-200 pb-4 mb-4'>
        <h4
          className={`font-soft font-semibold text-normal lg:text-lg cursor-pointer leading-snug hover:text-blue ${answerVisible ? 'text-blue' : 'text-black'}`}
          onClick={this.toggleAnswer}
        >
          {question}
        </h4>

        <div className={`font-sans font-normal text-black text-sm lg:text-base leading-snug mt-2 ${answerVisible ? 'visible' : 'hidden'}`}>
          {answer}
        </div>
      </li>
    );
  }
}

Question.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
};

Question.defaultProps = {
  question: '',
  answer: '',
};

export default Question;
