import React from 'react';
import Question from './questions/question';

const Questions = () => (
  <div className='bg-white'>
    <div className='container mx-auto py-16 px-8'>
      <h2 className='font-sans font-semibold text-blue text-xl lg:text-2xl mb-12'>
        Frequently Asked Questions
      </h2>

      <div className='flex flex-col lg:flex-row'>
        <div className='lg:w-2/3'>
          <ul>
            <Question
              question='Is there a cost to participate in the NL Genome Project?'
              answer='No. It is free to participate.'
            />

            <Question
              question='If my doctor isn’t listed, or If I don’t have a doctor, can I take part?'
              answer={['Only patients of participating doctors can take part at this time. If your doctor isn’t participating, you can ',
                <a href='/signup' className='text-blue underline'>sign up for our mailing list</a>,
                ' where we may announce new participating doctors or sign-up mechanisms in the future.']}
            />

            <Question
              question='Can I join the NL Genome Project online?'
              answer={['Yes! You can consent to join through a research nurse using a phone, video conferencing, and/or our Participant Portal.']}
            />

            <Question
              question='What happens if I join the NL Genome Project, but change my mind and want to leave?'
              answer={[
                'You can withdraw from (leave) the study at any point by contacting your participating doctor, or by contacting our ',
                <a href='/contact' className='text-blue underline'>Principal Investigators</a>,
                '. You will receive notification confirming your withdrawal.',
              ]}
            />

            <Question
              question='How many participants is the NL Genome Project accepting?'
              answer={['The NL Genome Project is currently enrolling approximately 10,000 volunteers on a first-come, first-served basis.']}
            />

            <Question
              question='I wasn’t born in Newfoundland and Labrador. Can I participate?'
              answer={['Yes. You are eligible to participate as long as you meet the eligibility criteria:',
                <ul className='ml-6 list-disc'>
                  <li>18 years of age or older</li>
                  <li>Have a valid MCP #</li>
                  <li>Able to provide written or electronic informed consent</li>
                  <li>Willing to be re-contacted</li>
                  <li>Willing to have your coded data shared with researchers both inside and outside of Canada</li>
                </ul>]}
            />

            <Question
              question='Will my quality of care be impacted if I don’t participate in the NL Genome Project?'
              answer='No, your quality of care will not be impacted. We understand that participating is a personal decision, and it’s completely up to you whether or not you join the study.'
            />

            <Question
              question='Do I get paid for this study?'
              answer='No. This study is completely volunteer-based. There is no compensation for participating, or reimbursement for any potential associated costs, such as travel to your doctor’s office.'
            />

            <Question
              question='Do doctors get paid for participating in the study?'
              answer='Participating doctors will be reimbursed for expenses related to participant enrolment in the NL Genome Project.'
            />

            <Question
              question='Why do you need to access my medical records?'
              answer={['Medical records will help our researchers get a fuller picture of your health and the genetic makeup of Newfoundland and Labrador. By linking records, like what tests you’ve undergone, to your genetics, Sequence Bio hopes to identify patterns and insights that could help our researchers develop new, safer treatments and medicines.',
                <a href='/privacy' className='text-blue underline'>Learn how we will protect your data.</a>,
              ]}
            />

            <Question
              question='Why do you need my saliva sample?'
              answer='Saliva is a non-invasive method for obtaining your DNA, and is far easier than obtaining blood, for example. With less than half a teaspoon (1-2 mL) of your saliva, we can get the information we need to begin to analyse your genetic code.'
            />

            <Question
              question='What is my genome?'
              answer='Your body is made up of trillions of cells, and in every cell, there’s a lot of stuff - including DNA.  DNA is the instruction manual that helps make you unique. All the information contained in your DNA is called your genome.'
            />

            <Question
              question='Where will my saliva sample go?'
              answer='Your sample will be securely shipped to and stored by Sequence Bio in St. John’s, who will then send it to an approved Canadian genomics laboratory. As world leaders in this field, these laboratories will perform the DNA analysis. Want to know which laboratories have been used to analyse your sample? Get in touch with the research nurse working with your doctor, or Sequence Bio, and we’ll let you know!'
            />

            <Question
              question='Will joining the study affect my insurance?'
              answer='It is against the law in Canada to be discriminated against because of your genetic makeup (including in cases of insurance). In Canada, the Genetic Non-Discrimination Act (Bill S-201) makes it illegal for someone to demand to see the results of a genetic test, to force individuals to take a genetic test, or to discriminate against a participant based on genetic test results. Sequence Bio and this study follows all laws put into effect by the Genetic Non-Discrimination Act. There is a risk that other people might not follow these laws. As a reminder, you may want to consult with a lawyer if you have further questions on this issue.'
            />

            <Question
              question='Will you sell my data?'
              answer='Individual data or data that identifies you will not be sold. We will only use your data in ways which you expressly agree to. We will never sell your saliva sample.'
            />

            <Question
              question='How does Sequence Bio make money?'
              answer={['The value of Sequence Bio’s research is in the findings and insights we hope to generate - not the data itself. Our business model is based on researching the information donated by participants to find potentially important patterns and insights. If our researchers develop these findings and insights, we will then work with approved, experienced partners to invest in these findings and bring new treatments and medicines to market. By leading the early stages of this research and development process, Sequence Bio can, like other biotechnology companies, capture a share of the long-term value of the medicines through licensing or other transactions.',
                <>
                  <br />
                  <br />
                </>,
                'Individual data or data that identifies you will not be sold. We will only use your data in ways which you expressly agree to. We will never sell your saliva sample.']}
            />

            <Question
              question='If I participate, will I get a share of any future profits?'
              answer='No. If Sequence Bio develops intellectual property and/or commercializes products or services, directly or indirectly, based on the results of this study, you will not receive any compensation.'
            />

            <Question
              question='How do I make a difference?'
              answer='The more people that participate, the more data we’ll be able to analyze. This might lead to higher quality insights and findings.'
            />

            <Question
              question='How could my saliva help find better medicines and treatments?'
              answer={['Saliva contains DNA, and thanks to advances in science and technology, researching human DNA is becoming a more affordable and accurate method for developing new medicines. By studying the DNA, medical records, and health information of 10,000 participants, the NL Genome Project will look for patterns and genetic changes that could potentially explain why some people are more likely to get sick, or why some medicines work better on some people, but not others. By understanding these patterns and genetic changes, our hope is to develop better, safer treatments.',
                <>
                  <br />
                  <br />
                </>,
                'Like other health research, there is no guarantee that this study will result in any significant breakthroughs.',
              ]}
            />

            <Question
              question='Will researchers see my personal information?'
              answer='Only authorized individuals, including NL Genome Project research team members and the study’s Principal Investigators, may see identifiable information about you. Even then, they only see this information when required for specific purposes, like contacting you about the project and returning findings. Access to data is controlled by Sequence Bio in Newfoundland and Labrador, and must be approved by the company’s Research Oversight Committee (ROC).'
            />

            <Question
              question='How will you keep my information safe?'
              answer={['Your medical and Genetic information is personal and private. Information that identifies you, like your name or birthday, is not used for research and is locked away in a separate encrypted database. Instead, you will only be identified by a unique Study Code when research is performed.',
                <>
                  <br />
                  <br />
                </>,
                'But we don’t stop there. We have an industry-leading security program that aligns with ISO27001/2 standards and we constantly scan our systems and devices to detect and mitigate  vulnerabilities. All information is encrypted using Advanced Encryption Standard (AES) 256 bit encryption and has multiple layers of security, all of which must be breached before there is any access to information. We can also auto-detect malicious intruders. But nothing is perfect, and we can’t give you 100% assurance. So if a breach does occur, we will let you know. We owe that transparency to all participants, and to the standards we hold ourselves to.',
                <>
                  <br />
                  <br />
                </>,
                'Please contact Sequence Bio’s Privacy Officer via email at ',
                <a href='mailto:privacy@sequencebio.com' className='text-blue underline'>privacy@sequencebio.com</a>,
                ' if you have any questions, concerns or complaints.',
              ]}
            />
          </ul>
        </div>

        <div className='flex-1 mt-6 lg:mt-0 lg:ml-16'>
          <div className='bg-grey-100 p-6 lg:p-8 rounded'>
            <p className='font-sans text-sm text-black mb-4'>
              We’re happy to answer any questions you may have, so don’t hesitate to contact us about anything we might have missed.
            </p>

            <a href='/contact' className='font-sans font-bold text-sm text-blue pb-1 border-b-2 border-yellow hover:text-blue-900'>
              Contact us
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Questions;
