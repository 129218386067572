import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/faq/hero';
import Questions from '../components/faq/questions';

const FaqPage = () => (
  <Layout>
    <SEO
      title='Frequently Asked Questions'
      description='Ask us anything! Plus, see what others wanted to know about the NL Genome Project, from how we use your information to the science behind it all.'
    />
    <Hero />
    <Questions />
  </Layout>
);

export default FaqPage;
