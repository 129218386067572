import React from 'react';

const Hero = () => (
  <div className='faq-hero'>
    <div className='container mx-auto pt-24 lg:pt-32 pb-16 px-8'>
      <div className='lg:w-2/5'>
        <h1 className='font-sans font-bold text-white text-4xl lg:text-5xl leading-very-tight mb-4'>
          Ask Us Anything
        </h1>

        <p className='font-sans font-normal text-white mb-4'>
        It’s important to us that we’re transparent about our information policies and practices, from how we use your information to the science behind it all.
        </p>
      </div>
    </div>
  </div>
);

export default Hero;
